import React from "react"
import { Link } from "gatsby"
import { LinkedinShareButton, EmailShareButton } from "react-share"

import t from "locale"

const NewsTile = ({ title, desc, link, img, date, variant, lang }) => {
  return variant === "big" ? (
    <div className="news-tile news-tile--big">
      <div className="news-tile__date">{date}</div>
      <div className="news-tile__newest-label">{t("Newest", lang)}</div>
      <div className="row no-gutters">
        <div className="col-lg-6">
          <div className="news-tile__content">
            <Link to={link}>
              <h2 className="news-tile__title">{title}</h2>
            </Link>
            <Link to={link}>
              <div className="news-tile__desc">{desc}</div>
            </Link>
            <Link to={link} className="news-tile__link">
              {t("Read more", lang)}
            </Link>
            <div className="news-tile__socials">
              <LinkedinShareButton
                url={link}
                className="news-tile__single-social-link"
              >
                <img
                  src={require("../assets/images/linkedin-ico.svg")}
                  alt=""
                />
              </LinkedinShareButton>
              <div className="news-tile__divider"></div>
              <EmailShareButton
                url={link}
                className="news-tile__single-social-link"
              >
                <img src={require("../assets/images/email-ico.svg")} alt="" />
              </EmailShareButton>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <Link to={link}>
            <div className="news-tile__thumbnail-wrapper">
              <div
                className="news-tile__thumbnail"
                style={{ backgroundImage: `url(${img})` }}
              />
            </div>
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <div className="news-tile">
      <div className="news-tile__date">{date}</div>
      <Link to={link}>
        <div className="news-tile__thumbnail-wrapper">
          <div
            className="news-tile__thumbnail"
            style={{ backgroundImage: `url(${img})` }}
          />
        </div>
      </Link>
      <div className="news-tile__content">
        <Link to={link}>
          <h2 className="news-tile__title">{title}</h2>
        </Link>
        <Link to={link}>
          <div className="news-tile__desc">{desc}</div>
        </Link>
        <Link to={link} className="news-tile__link">
          {t("Read more", lang)}
        </Link>
        <div className="news-tile__socials">
          <LinkedinShareButton
            url={link}
            className="news-tile__single-social-link"
          >
            <img src={require("../assets/images/linkedin-ico.svg")} alt="" />
          </LinkedinShareButton>
          <div className="news-tile__divider"></div>
          <EmailShareButton
            url={link}
            className="news-tile__single-social-link"
          >
            <img src={require("../assets/images/email-ico.svg")} alt="" />
          </EmailShareButton>
        </div>
      </div>
    </div>
  )
}

export default NewsTile
