import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import NewsTile from "../components/newsTile"

import t from "locale"

const NewsPage = ({ data }) => {
  const lang = "en"
  const posts = data.allWpPost.nodes
  return (
    <Layout lang={lang}>
      <SEO
        title="News - Hiddenfjord"
        image="https://hiddenfjord.com/og-image.png"
      />
      <section className="news-page">
        <div className="container">
          <h1 className="news-page__title">{t("News", lang)}</h1>
          <div className="row">
            {posts.map((post, index) => {
              return (
                <div
                  className={index === 0 ? "col-12" : "col-lg-4 col-md-6"}
                  key={index}
                >
                  <NewsTile
                    title={post.title}
                    desc={post.postAcf.shortDesc}
                    img={
                      post.featuredImage && post.featuredImage.node.sourceUrl
                    }
                    link={post.uri}
                    date={post.date}
                    variant={index === 0 ? "big" : ""}
                    lang={lang}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query allPosts {
    allWpPost(
      sort: { fields: date, order: DESC }
      filter: { language: { slug: { eq: "en" } } }
    ) {
      nodes {
        title
        slug
        uri
        date(formatString: "DD.MM.YYYY")
        postAcf {
          shortDesc
        }
        seo {
          metaDesc
          title
        }
        featuredImage {
          node {
            sourceUrl
          }
        }
        content
      }
    }
  }
`

export default NewsPage
